
import { useStore } from '@/Store';
import { REMOVE_LABEL_FROM_CART, FULL_FILL_LABEL } from '@/Store/actions';
import { computed } from '@vue/reactivity';
import { defineComponent, ref } from 'vue';


export default defineComponent({
    name: "Label",
    props: ["label", "incomplete"],
    setup(props) {
        const store = useStore()

        const preLoading = ref(false)

        const loading = computed(() => {
            return store.state.LabelState.labelsCanFullFillList.find((element) => element.id === props.label.id)?.loading ||
            store.state.LabelState.labelsOnCart.find((element) => element.id === props.label.id)?.loading
        })

        const remove = () => {
            preLoading.value = true
            store.dispatch(REMOVE_LABEL_FROM_CART, props.label)
        }

        const fullFill = () => {
            preLoading.value = true
            store.dispatch(FULL_FILL_LABEL, props.label)
        }

        const click = () => {
            if (props.label?.can_full_fill === false) {
                remove()
            } else {
                if (!props.label?.full_filled) {
                    fullFill()
                }
            }
        }

        return {
            click,
            preLoading,
            loading
        }
    }
})
