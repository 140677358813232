
import { computed, defineComponent } from 'vue';
import { useStore } from '@/Store';
import { GET_ORDERS_TO_PRINT, GET_ORDERS_WAS_PRINTED, SET_ORDER_PRINTED } from "@/Store/actions"
import Order from '@/Interfaces/Order';

export default defineComponent({
    name: "OrdersToPrint",
    setup() {
        const store = useStore()
        const ordersToPrint = computed(() => store.state.OrderState.OrdersToPrint)
        const ordersWasPrinted = computed(() => store.state.OrderState.OrdersWasPrinted)
        const baseUrl = "https://ateliefofurasdarose.lojavirtualnuvem.com.br/admin/orders/"

        const updateOrdersToPrint = () => {
            store.dispatch(GET_ORDERS_TO_PRINT)
        }

        const updateOrdersWasPrinted = () => {
            store.dispatch(GET_ORDERS_WAS_PRINTED)
        }

        const openOrderPage = (orderId: number) => {
            window.open(`${baseUrl}${orderId}`, "_blank")
        }

        const print = (order: Order) => {
            const btn = document.querySelector(`#btn${order.id}`)
            openOrderPage(order.id)
            btn?.classList.add("disabled")
            store.dispatch(SET_ORDER_PRINTED, order)
            updateOrdersWasPrinted()
        }

        const print10 = () => {
            for (let i = 0; i < 10; i++) {
                print(ordersToPrint.value[i])
            }
        }

        updateOrdersToPrint()
        updateOrdersWasPrinted()

        return {
            ordersToPrint,
            ordersWasPrinted,
            print,
            print10,
            openOrderPage,
            baseUrl
        }
    }
})
