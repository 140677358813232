
import Auth from '@/Auth';
import { useStore } from '@/Store';
import { computed } from '@vue/reactivity';
import { defineComponent } from 'vue';
import Navitem from './Navitem.vue';

export default defineComponent({
    name: "Offcanvasbody",
    watch: {
        $route() {
            setTimeout(() => {
                document.getElementById("btnOffCanvasClose")?.click()
            }, 100)
        }
    },
    setup() {
        const store = useStore()

        const user = computed(() => store.state.UserState.user)

        const logout = () => {
            Auth.logout(store)
        }

        return {
            user,
            logout
        }
    },
    components: {
        Navitem
    }
})
