import ProductReference from "@/Interfaces/ProductsReference/ProductReference";
import { Module } from "vuex";
import { State } from "..";
import http from "@/Http"
import { GET_PRODUCTS_REFERENCE, SELECT_PRODUCT_REFERENCE } from "../actions";
import { ALERT, PRODUCT_LIST_NEXT_PAGE, PRODUCT_LIST_PREVIOUS_PAGE, PRODUCT_LIST_SET_PAGE, SET_PRODUCTS_REFERENCE_LIST_STATE, SET_PRODUCT_REFERENCE_SELECTED_STATE } from "../mutations";
import { AlertType } from "@/Interfaces/Alert";

export interface ProductsStateInterface {
    ProductsList: ProductReference[]
    ProductSelected?: ProductReference,
    ProductListPageActive: number,
    ProductListSearchValue?: string
}

export const ProductsState: Module<ProductsStateInterface, State> = {
    mutations: {
        [SET_PRODUCTS_REFERENCE_LIST_STATE](state, productList: ProductReference[]) {
            state.ProductsList = productList
        },
        [SET_PRODUCT_REFERENCE_SELECTED_STATE](state, productSelected: ProductReference) {
            state.ProductSelected = productSelected
        },
        [PRODUCT_LIST_NEXT_PAGE](state) {
            state.ProductListPageActive += 1
        },
        [PRODUCT_LIST_PREVIOUS_PAGE](state) {
            state.ProductListPageActive -= 1
        },
        [PRODUCT_LIST_SET_PAGE](state, page: number) {
            state.ProductListPageActive = page
        }
    },
    actions: {
        [GET_PRODUCTS_REFERENCE]({ commit, dispatch }, params) {
            http.get("/produtos", { params: params })
                .then(response => {
                    const productListResponse = response.data as Array<any>
                    const productListForState: Array<ProductReference> = []
                    productListResponse.forEach(productData => {
                        productListForState.push({
                            id: productData.id,
                            img: productData.images[0].src,
                            name: productData.name.pt,
                            sku: productData.variants[0].sku
                        })
                    })
                    commit(SET_PRODUCTS_REFERENCE_LIST_STATE, productListForState)
                })
                .catch(exc => {
                    if (exc.response.status == 401) {
                        window.location.reload()
                    } else {
                        commit(ALERT, {
                            text: "Nada foi encontrado!",
                            type: AlertType.INFO
                        })
                        dispatch(GET_PRODUCTS_REFERENCE, { page: 1 })
                    }
                })
        },
        [SELECT_PRODUCT_REFERENCE]({ commit }, id: string) {
            http.get(`/produtos/${id}`)
                .then(response => {
                    const productData = response.data
                    const productToSelect = {
                        id: productData.id,
                        img: productData.images[0].src,
                        name: productData.name.pt,
                        sku: productData.variants[0].sku
                    }
                    commit(SET_PRODUCT_REFERENCE_SELECTED_STATE, productToSelect)
                })
        }
    }
}