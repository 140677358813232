
import { computed, defineComponent } from "vue";
import { useStore } from "@/Store";
import { FLUSH_ALL_ORDERS_SHIPPING } from "@/Store/actions";
import OrdersShippingCount from "@/components/OrdersShipping/OrdersShippingCount.vue";
import OrdersShippingList from "@/components/OrdersShipping/OrdersShippingList.vue";


export default defineComponent({
    name: "OrdersShippingPage",
    components: { OrdersShippingCount, OrdersShippingList },
    setup() {
        const store = useStore()
        const toProduct = computed(() => store.state.OrdersShippingState.toProduct)
        const toPrint = computed(() => store.state.OrdersShippingState.toPrint)
        const inProduction = computed(() => store.state.OrdersShippingState.inProduction)

        store.dispatch(FLUSH_ALL_ORDERS_SHIPPING)

        return {
            toProduct,
            toPrint,
            inProduction
        }
    }
})
