import User from "@/Interfaces/User"
import { Module } from "vuex"
import { State } from ".."
import { GET_TOKEN_LOGIN } from "../actions"
import { SET_USER_STATE, DELETE_USER_STATE, ALERT } from "../mutations"
import http from "@/Http"
import { AlertType } from "@/Interfaces/Alert"
import Auth from "@/Auth"

export interface UserStateInterface {
    user: User | null
}

export const UserState: Module<UserStateInterface, State> = {
    mutations: {
        [SET_USER_STATE](state, user: User) {
            Auth.localRegister(user)
            user.password = undefined
            state.user = user
        },
        [DELETE_USER_STATE](state) {
            Auth.localLogout(state.user as User)
            state.user = null
        }
    },
    actions: {
        [GET_TOKEN_LOGIN]({ commit }, user: User) {
            http.post("/login", {
                username: user.username,
                password: user.password
            })
            .then(response => {
                user.token = response.data
                commit(SET_USER_STATE, user)
                commit(ALERT, {
                    text: `Olá ${user.username}, seja bem-vindo(a)!`,
                    type: AlertType.SUCCESS
                })
            })
            .catch(res => {
                console.log(res)
                let textAlert = "Usuário ou senha incorretos!"
                let typeAlert = AlertType.DANGER
                if (res.code.includes("NETWORK")) {
                    textAlert = "Erro de conexão tente novamente!"
                    typeAlert = AlertType.DANGER
                }
                commit(ALERT, {
                    text: textAlert,
                    type: typeAlert
                })
            })
        }
    }
}