import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-12675f5d"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "ordersShippingList",
  class: "d-flex flex-column border border-dark rounded px-2 py-3 mx-auto bg-pinkfofuras"
}
const _hoisted_2 = {
  id: "ordersShippingListHead",
  class: "nav nav-tabs justify-content-center rounded-top"
}
const _hoisted_3 = ["id"]
const _hoisted_4 = { id: "ordersShippingListBody" }
const _hoisted_5 = { class: "list-group list-group-flush rounded-bottom" }
const _hoisted_6 = ["id", "innerHTML", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pageOptions, (page, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "nav-item",
          key: index
        }, [
          _createElementVNode("a", {
            class: _normalizeClass(["nav-link", _ctx.activePage == index ? 'active' : '']),
            id: (index as unknown as string),
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.changePage && _ctx.changePage(...args)))
          }, _toDisplayString(page.name), 11, _hoisted_3)
        ]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("ul", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getActiveList(), (orderShipping, index) => {
          return (_openBlock(), _createElementBlock("li", {
            class: "list-group-item d-flex flex-row justify-content-between",
            key: index
          }, [
            _createTextVNode(" Remessa #" + _toDisplayString(orderShipping.id) + " ", 1),
            (_ctx.getActivePage().button != undefined)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  id: 'buttonId' + orderShipping.id,
                  class: "btn btn-primary",
                  innerHTML: _ctx.getActivePage().button,
                  onClick: (e) => { _ctx.buttonClicked(e as PointerEvent, orderShipping.id); _ctx.getActivePage().action(orderShipping) } 
                }, null, 8, _hoisted_6))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ])
    ])
  ]))
}