
import { computed, defineComponent } from 'vue';
import { useStore } from '@/Store';
import { GET_IMPORTED_ORDERS_SHOPEE, GET_ORDERS_ON_PRODUCTION_SHOPEE } from '@/Store/actions';

    export default defineComponent({
        name: "OrdersVizualization",
        setup() {
            const store = useStore()

            const updateImportedList = () => {
                store.dispatch(GET_IMPORTED_ORDERS_SHOPEE)
            }
            const updateProductionList = () => {
                store.dispatch(GET_ORDERS_ON_PRODUCTION_SHOPEE)
            }

            updateImportedList()
            updateProductionList()
            
            return {
                importedList: computed(() => store.state.ShopeeState.importados),
                productionList: computed(() => store.state.ShopeeState.producao)
            }
        }
    })
