
import { useStore } from '@/Store';
import { GET_LABELS_CAN_FULL_FILL_LIST } from '@/Store/actions';
import { computed } from '@vue/reactivity';
import { defineComponent, ref, watchEffect } from 'vue';
import ButtonReload from '../ButtonReload.vue';
import Label from './Label.vue';
import Paginator from '../Paginator.vue';
import { switchCase } from '@babel/types';


export default defineComponent({
    name: "CanFullFillLabels",
    components: {
        ButtonReload,
        Label,
        Paginator
    },
    setup() {
        const store = useStore()

        const activePage = ref(1)

        
        const reloading = () => {
            store.dispatch(GET_LABELS_CAN_FULL_FILL_LIST, {page: activePage.value})
        }
        
        const switchPage = (page: number) => {
            activePage.value = page
            reloading()
        }
        

        const labelsCanFullFillList = computed(() => store.state.LabelState.labelsCanFullFillList)

        reloading()

        return {
            labelsCanFullFillList,
            reloading,
            switchPage
        }
    }
})
