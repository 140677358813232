
import { defineComponent, ref } from "vue";

export default defineComponent({
    name: "Cutter",
    props:{
        sku: String
    },
    emits: ["checked"],
    setup(props) {
        const cutter = ref(props.sku)
        return {
            cutter
        }
    }
})

