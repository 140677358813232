
import {defineComponent} from 'vue';
import Offcanvas from '../Offcanvas/Offcanvas.vue';

export default defineComponent({
  name: "Menu",
  components: {
    Offcanvas
  }
})
