import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { id: "teste" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Cutter = _resolveComponent("Cutter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(10, (i) => {
      return _createVNode(_component_Cutter, {
        sku: "CF5214",
        key: i,
        onChecked: _ctx.log
      }, null, 8, ["onChecked"])
    }), 64))
  ]))
}