
import { defineComponent } from 'vue';
import { useStore } from '@/Store';
import { GET_IMPORTED_ORDERS_SHOPEE, GET_ORDERS_ON_PRODUCTION_SHOPEE, SEND_FILE_TO_IMPORT_SHOPEE } from '@/Store/actions';
import { ref } from 'vue';

export default defineComponent({
    name: "ImportOrdersArea",
    setup() {
        const store = useStore()
        const loading = ref(false)

        const sendFile = (e: Event) => {
            loading.value = true
            const target = e.target as HTMLFormElement
            const formData = new FormData()
            formData.append("file", target.file.files[0])
            store.dispatch(SEND_FILE_TO_IMPORT_SHOPEE, formData).then(() => {
                setTimeout(() => {
                    target.file.value = ""
                    loading.value = false
                    store.dispatch(GET_IMPORTED_ORDERS_SHOPEE)
                    store.dispatch(GET_ORDERS_ON_PRODUCTION_SHOPEE)
                }, 1000)
            })
        }

        return {
            sendFile,
            loading
        }
    }
})
