
import { defineComponent, ref } from 'vue';

export default defineComponent({
    name: "ButtonReload",
    setup() {
        const reloadingAnim = ref(false)

        const reloading = () => {
            reloadingAnim.value = true
            setTimeout(() => {
                reloadingAnim.value = false
            }, 850 * 2)
        }

        setInterval(reloading, 30000)

        return {
            reloadingAnim,
            reloading
        }
    }
})
