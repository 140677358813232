
import { defineComponent } from 'vue';
import Shortcut from '@/components/Home/Shortcut.vue';

export default defineComponent({
    name: "ProductsHome",
    setup() {
        return {};
    },
    components: { Shortcut }
})
