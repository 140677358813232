
import { computed, defineComponent } from 'vue';

export default defineComponent({
    name: "OrdersShippingCount",
    props: ["ordersShippings"],
    setup(props) {
        const ordersShippings = computed(() => props.ordersShippings)
        const quantityOfShippings = computed(() => {
            let shippings = ordersShippings.value
            if (shippings != undefined) {
                return shippings.length
            }
            return false
        })

        return {
            quantityOfShippings
        }
    }
})
