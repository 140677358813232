
import { defineComponent, ref } from 'vue';
import http from "@/Http"
import { useRoute } from 'vue-router';
import PositionRegister from './PositionRegister.vue';

export default defineComponent({
    name: "TablePositions",
    setup() {
        const possibleCols = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X"];
        const possibleRows = [1, 2, 3, 4, 5, 6, 7, 8];
        const rangeCols = ref([0]);
        const rangeRows = ref([0]);
        const positionObj = ref({
            column: "",
            created_at: null,
            id: 0,
            panel_id: 0,
            product_reference_id: 0,
            row: 0,
            updated_at: ""
        });
        const panel = ref("");
        http.get(`produtos/${useRoute().params.id}/posicao`)
            .then(response => {
            if (!response.data.id) {
                positionObj.value.id = -1;
                return;
            }
            positionObj.value = response.data;
            const position = positionObj.value;
            const indexColPosition = possibleCols.findIndex(item => item == position.column);
            if (indexColPosition < 2) {
                rangeCols.value = [0, 1, 2, 3, 4];
            }
            else if (indexColPosition > 21) {
                rangeCols.value = [19, 20, 21, 22, 23];
            }
            else {
                rangeCols.value = [indexColPosition - 2, indexColPosition - 1, indexColPosition, indexColPosition + 1, indexColPosition + 2];
            }
            const indexRowPosition = possibleRows.findIndex(item => item == position.row);
            if (indexRowPosition < 2) {
                rangeRows.value = [0, 1, 2, 3, 4];
            }
            else if (indexRowPosition > 5) {
                rangeRows.value = [3, 4, 5, 6, 7];
            }
            else {
                rangeRows.value = [indexRowPosition - 2, indexRowPosition - 1, indexRowPosition, indexRowPosition + 1, indexRowPosition + 2];
            }
            if (position.panel_id) {
                http.get(`paineis/${position.panel_id}`)
                    .then(response => {
                    panel.value = response.data.letter || "";
                });
            }
        });
        const printX = (col: number, row: number) => {
            if (possibleCols[col] == positionObj.value.column && possibleRows[row] == positionObj.value.row) {
                return "X";
            }
        };
        return {
            possibleCols,
            possibleRows,
            positionObj,
            rangeCols,
            rangeRows,
            printX,
            panel
        };
    },
    components: { PositionRegister }
})
