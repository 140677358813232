
import { defineComponent, ref, watchEffect } from 'vue';
import CartLabels from '@/components/MelhorEnvio/CartLabels.vue';
import CanFullFillLabels from '@/components/MelhorEnvio/CanFullFillLabels.vue';
import http from "@/Http"


export default defineComponent({
    name: "Melhorenvio",
    components: {
        CartLabels,
        CanFullFillLabels
    },
    setup() {

        const connection = ref(true)

        watchEffect(() => {
            if (connection.value === false) {
                http.get("/melhorenvio/connect")
                .then(response => {
                    document.location = response.data                    
                })
                .catch(() => {
                    document.location.reload()
                })
            }
        })

        return {
            connection
        }
    }
})
