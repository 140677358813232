
import { useStore } from '@/Store';
import { GET_PRODUCTS_REFERENCE } from '@/Store/actions';
import { computed, ref } from '@vue/reactivity';
import { defineComponent, watchEffect } from 'vue';
import ProductItem from './ProductItem.vue';
import Paginator from '../Paginator.vue';
import Auth from '@/Auth';

export default defineComponent({
    name: "ProductsList",
    components: {
        ProductItem,
        Paginator
    },
    setup() {
        const store = useStore()

        const ProductsList = computed(() => store.state.ProductsState.ProductsList)

        const activePage = ref(1)

        const changeActivePage = (page: number) => {
            activePage.value = page
            search()
        }

        const searchValue = ref(store.state.ProductsState.ProductListSearchValue || "")
        const searchLoading = ref(false)

        const search = () => {
            if (searchValue.value.trim() !== "") {
                store.state.ProductsState.ProductListSearchValue = searchValue.value
            }
            searchLoading.value = true
            store.dispatch(GET_PRODUCTS_REFERENCE, searchValue.value.trim() !== "" ? { q: searchValue.value, page: activePage.value } : { page: activePage.value })
        }
        
        if (!ProductsList.value && Auth.user(store)) {
            search()
        }

        watchEffect(() => {
            if (ProductsList.value !== undefined) {
                searchLoading.value = false
            }
            ProductsList.value
        })

        return {
            ProductsList,
            searchValue,
            searchLoading,
            search,
            changeActivePage
        }
    }
})
