
import { useStore } from '@/Store';
import { defineComponent, ref } from 'vue';
import router from "@/router"
import { SET_PRODUCT_REFERENCE_SELECTED_STATE } from '@/Store/mutations';

export default defineComponent({
    name: "ProductItem",
    props: ["productID"],
    setup(props) {
        const store = useStore()

        const product = store.state.ProductsState.ProductsList.find(product => product.id === props.productID)

        const imgLoading = ref(true)

        const selectProduct = () => {
            store.commit(SET_PRODUCT_REFERENCE_SELECTED_STATE, product)
            router.push({ name: "Produto", params: { id: product?.id } })
            window.scrollTo(0, 0)
        }

        return {
            product,
            imgLoading,
            selectProduct
        }
    }
})
