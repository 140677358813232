
import { useStore } from '@/Store';
import { computed } from '@vue/reactivity';
import { defineComponent } from 'vue';
import { AlertType } from '@/Interfaces/Alert';

export default defineComponent({
    name: "Alert",
    setup() {
        const store = useStore()

        const AlertTypesAssoc = {
            [AlertType.PRIMARY]: "primary",
            [AlertType.SECONDARY]: "secondary",
            [AlertType.SUCCESS]: "success",
            [AlertType.DANGER]: "danger",
            [AlertType.WARNING]: "warning",
            [AlertType.INFO]: "info",
            [AlertType.LIGHT]: "light",
            [AlertType.DARK]: "dark"
        }

        return {
            alert: computed(() => store.state.AlertState.alert),
            AlertTypesAssoc
        }
    }
})
