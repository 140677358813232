import Panel from "@/Interfaces/Panels/Panel"
import PanelPosition from "@/Interfaces/Panels/PanelPosition"
import { Module } from "vuex"
import { State } from ".."
import { GET_PANELS_LIST, GET_POSITIONS_LIST, POST_PRODUCT_POSITION } from "../actions"
import { SET_PANELS_LIST_STATE, SET_POSITIONS_LIST_STATE } from "../mutations"
import http from "@/Http"

export interface PanelPositionInterface {
    PanelsList: Panel[]
    PositionsList: PanelPosition[]
}

export const PanelPositionsState: Module<PanelPositionInterface, State> = {
    mutations: {
        [SET_PANELS_LIST_STATE](state, panelsList: Panel[]) {
            state.PanelsList = panelsList
        },
        [SET_POSITIONS_LIST_STATE](state, positionsList: PanelPosition[]) {
            state.PositionsList = positionsList
        }
    },
    actions: {
        [GET_PANELS_LIST]({ commit }) {
            http.get("paineis")
                .then(response => {
                    const panelsList = [] as Panel[]
                    const panelsData = response.data.data
                    for (const panelData of panelsData) {
                        panelsList.push({
                            id: panelData.id,
                            letter: panelData.letter
                        })
                    }
                    commit(SET_PANELS_LIST_STATE, panelsList)
                })
        },
        [GET_POSITIONS_LIST]({ commit }, { id }: Panel) {
            http.get(`paineis/${id}/posicoes`)
                .then(response => {
                    const positionsList = [] as PanelPosition[]
                    const positionsArrayData = response.data as Iterable<any>
                    for (const [index, positionData] of Object.entries(positionsArrayData)) {
                        positionsList.push({
                            column: positionData.column,
                            panel_id: positionData.panel_id,
                            row: positionData.row,
                            id: positionData.id,
                            product_id: positionData.product_reference_id
                        })
                    }
                    commit(SET_POSITIONS_LIST_STATE, positionsList)
                })
        },
        [POST_PRODUCT_POSITION](_, panelPosition: PanelPosition) {
            http.post(`paineis/${panelPosition.panel_id}/posicoes/${panelPosition.id}/${panelPosition.product_id}`)
            .then(() => {
                window.location.reload()
            })
        }
    }
}