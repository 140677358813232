import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a957893c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "OrdersToPrint" }
const _hoisted_2 = {
  key: 0,
  class: "spinner-border spinner-border-sm",
  role: "status"
}
const _hoisted_3 = { class: "list-group mt-4" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = {
  key: 0,
  class: "spinner-border spinner-border-sm",
  role: "status"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: "btn btn-primary",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.reload && _ctx.reload(...args)))
    }, [
      _createTextVNode("Atualizar "),
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("ul", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.toPrintList, (order) => {
        return (_openBlock(), _createElementBlock("li", {
          class: "list-group-item d-flex justify-content-between",
          key: order.id
        }, [
          _createTextVNode(_toDisplayString(order.order) + " ", 1),
          _createElementVNode("button", {
            class: "btn btn-primary",
            onClick: ($event: any) => (_ctx.print(order.order))
          }, [
            _createTextVNode(" Imprimir "),
            (_ctx.printing.includes(order.order))
              ? (_openBlock(), _createElementBlock("div", _hoisted_5))
              : _createCommentVNode("", true)
          ], 8, _hoisted_4)
        ]))
      }), 128))
    ])
  ]))
}