
import { defineComponent } from 'vue';
import Auth from "@/Auth"
import { useStore } from '@/Store';
import { computed } from '@vue/reactivity';
import ProductsList from '@/components/Products/ProductsList.vue';

export default defineComponent({
    name: "Products",
    components: {
        ProductsList
    },
    setup() {
        const store = useStore()
        return {
            user: computed(() => Auth.user(store))
        }
    },
})
