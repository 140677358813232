import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3173c5e7"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "container-fluid text-center py-3 bg-skincolorfofuras",
  id: "footer"
}
const _hoisted_2 = { class: "m-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h6", _hoisted_2, "©Fofuras da Rose - " + _toDisplayString((new Date()).getFullYear()), 1)
  ]))
}