import { Module } from "vuex";
import { State } from "..";
import http from "@/Http";
import OrdersShipping from "@/Interfaces/OrdersShipping/OrdersShipping";
import { SET_SHIPPINGS_TO_PRODUCT_LIST_STATE, SET_SHOPEE_PRODUCTION_LIST_STATE } from "../mutations";
import { GET_CUTTERS_TO_PRODUCT_FROM_SHIPPINGS_WAS_IN_PRODUCTION, GET_ORDERS_SHIPPING_WAS_IN_PRODUCTION_TO_CUTTER, GET_PRODUCTS_TO_PRODUCT_FROM_SHOPEE } from "../actions";

export interface CutterProductionStateInterface {
    shippingsToProduct: OrdersShipping[]
    shopeeProduction?: []
}

export const CutterProductionState: Module<CutterProductionStateInterface, State> = {
    mutations: {
        [SET_SHIPPINGS_TO_PRODUCT_LIST_STATE](state, shippingsToProduct) {
            state.shippingsToProduct = shippingsToProduct
        },
        [SET_SHOPEE_PRODUCTION_LIST_STATE](state, productsToProduct) {
            state.shopeeProduction = productsToProduct
        }
    },
    actions: {
        [GET_ORDERS_SHIPPING_WAS_IN_PRODUCTION_TO_CUTTER]({ commit, dispatch }) {
            http.get("/producao/remessa?inProduction=true")
            .then((res) => {
                commit(SET_SHIPPINGS_TO_PRODUCT_LIST_STATE, res.data)
                dispatch(GET_CUTTERS_TO_PRODUCT_FROM_SHIPPINGS_WAS_IN_PRODUCTION)
            })
        },
        [GET_CUTTERS_TO_PRODUCT_FROM_SHIPPINGS_WAS_IN_PRODUCTION]({ commit, state }) {
            state.shippingsToProduct.forEach((shipping) => {
                http.get(`/producao/remessa/${shipping.id}/produtos`)
                .then((res) => {
                    console.log(res.data.filter((value: any) => value.prefix == "CF"))
                    console.log(res.data)
                })
                
            })
        },
        [GET_PRODUCTS_TO_PRODUCT_FROM_SHOPEE]({ commit }) {
            const cuttersToProduct: any = []
            for (let i = 1; i <= 2; i++) {
                http.get(`/producao/shopee?page=${i}`)
                .then((res) => {
                    const productFromShopee = res.data
                    cuttersToProduct.push(productFromShopee.filter((value: any) => value.prefix == "CF"))
                })
                
            }
            commit(SET_SHOPEE_PRODUCTION_LIST_STATE, cuttersToProduct)
        }
    }
}