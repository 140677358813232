import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("img", {
    style: _normalizeStyle(`opacity: ${_ctx.opacity};`),
    src: _ctx.imgsrc,
    class: "img-thumbnail shadow my-2",
    alt: _ctx.imgalt,
    width: "150",
    height: "150",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.routerPush && _ctx.routerPush(...args)))
  }, null, 12, _hoisted_1))
}