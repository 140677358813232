import LabelMelhorEnvio from "@/Interfaces/LabelMelhorEnvio/LabelMelhorEnvio";
import { Module } from "vuex";
import { State } from "..";
import { ADD_LABEL_ON_ADD_TO_CART_STATE, REMOVE_LABEL_ON_ADD_TO_CART_STATE, SET_LABEL_CART_STATE, SET_LABEL_CAN_FULL_FILL_LIST_STATE, LABEL_IS_LOADING, ALERT } from "../mutations";
import http from "@/Http"
import { ADD_LABEL_FROM_ADD_TO_CART_ON_CART, FULL_FILL_LABEL, GET_LABELS_CAN_FULL_FILL_LIST, GET_LABELS_ON_CART, REMOVE_LABEL_FROM_CART } from "../actions";
import { AlertType } from "@/Interfaces/Alert";

export interface LabelStateInterface {
    labelsCanFullFillList: LabelMelhorEnvio[],
    labelsOnCart: LabelMelhorEnvio[],
    labelsToAddOnCart: LabelMelhorEnvio[]
}

export const LabelState: Module<LabelStateInterface, State> = {
    mutations: {
        [SET_LABEL_CAN_FULL_FILL_LIST_STATE](state, labelsList: LabelMelhorEnvio[]) {
            state.labelsCanFullFillList = labelsList
        },
        [SET_LABEL_CART_STATE](state, labelsList: LabelMelhorEnvio[]) {
            state.labelsOnCart = labelsList
        },
        [ADD_LABEL_ON_ADD_TO_CART_STATE](state, label: LabelMelhorEnvio) {
            if (state.labelsToAddOnCart === undefined) {
                state.labelsToAddOnCart = [label]
            } else {
                state.labelsToAddOnCart.push(label)
            }
        },
        [REMOVE_LABEL_ON_ADD_TO_CART_STATE](state, { order }: LabelMelhorEnvio) {
            let pass = false
            state.labelsToAddOnCart = state.labelsToAddOnCart.filter(label => {
                if (label.order != order) {
                    return true
                } else {
                    if (pass === true) {
                        return pass
                    } else {
                        pass = true
                        return false
                    }
                }
            })
        },
        [LABEL_IS_LOADING](state, label: LabelMelhorEnvio) {
            state.labelsOnCart = state.labelsOnCart.map((element) => {
                if (element.id === label.id) {
                    element.loading = true
                }
                return element
            })
            state.labelsCanFullFillList = state.labelsCanFullFillList.map((element) => {
                if (element.id === label.id) {
                    element.loading = true
                }
                return element
            })
        }
    },
    actions: {
        [GET_LABELS_ON_CART]({ commit }, labelToRemove?: LabelMelhorEnvio) {
            const labelsOnCart: Array<LabelMelhorEnvio> = []
            http.get("melhorenvio/etiquetas/carrinho")
                .then(response => {
                    const labels = response.data
                    for (const label of labels) {
                        if (!label) continue
                        labelsOnCart.push({
                            id: label.label_id as string,
                            order: label.order_number as number,
                            client: label.client_name as string,
                            can_full_fill: label.can_full_fill as boolean,
                            full_filled: label.full_filled as boolean
                        })
                    }
                    const labelsOnCartReverse = labelsOnCart.reverse()
                    if (labelToRemove) {
                        commit(REMOVE_LABEL_ON_ADD_TO_CART_STATE, labelToRemove)
                    }
                    commit(SET_LABEL_CART_STATE, labelsOnCartReverse)
                })
        },
        [ADD_LABEL_FROM_ADD_TO_CART_ON_CART]({ dispatch, commit }, label: LabelMelhorEnvio) {
            http.post("melhorenvio/etiquetas", {
                order: label.order
            })
                .then(() => {
                    dispatch(GET_LABELS_ON_CART, label)
                })
                .catch(() => {
                    commit(ALERT, {
                        text: "Erro! verifique o pedido e tente novamente!",
                        type: AlertType.DANGER
                    })
                    commit(REMOVE_LABEL_ON_ADD_TO_CART_STATE, label)
                })
        },
        [REMOVE_LABEL_FROM_CART]({ dispatch }, label: LabelMelhorEnvio) {
            http.delete(`melhorenvio/etiquetas/${label.id}`)
                .then(() => {
                    dispatch(GET_LABELS_ON_CART)
                })
        },
        [GET_LABELS_CAN_FULL_FILL_LIST]({commit}, options?) {
            const labelsList: LabelMelhorEnvio[] = []
            http.get("melhorenvio/etiquetas/refresh")
            .finally(() => {
                http.get("melhorenvio/etiquetas/liberadas", {params: options || {}})
                .then(response => {
                    const labels = response.data.data
                    for (const label of labels) {
                        if (!label) continue
                        labelsList.push({
                            id: label.label_id as string,
                            order: label.order_number as number,
                            client: label.client_name as string,
                            can_full_fill: label.can_full_fill as boolean,
                            full_filled: label.full_filled as boolean
                        })
                    }
                    commit(SET_LABEL_CAN_FULL_FILL_LIST_STATE, labelsList)
                })
            })
        },
        [FULL_FILL_LABEL]({dispatch, commit}, label: LabelMelhorEnvio) {
            http.get(`melhorenvio/etiquetas/${label.id}/enviar`)
            .then(() => {
                commit(LABEL_IS_LOADING, label)
                dispatch(GET_LABELS_CAN_FULL_FILL_LIST)
            })
        }
    }
}