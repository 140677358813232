import { Module } from "vuex";
import { State } from "..";
import {
  GET_IMPORTED_ORDERS_SHOPEE,
  GET_ORDERS_ON_PRODUCTION_SHOPEE,
  SEND_FILE_TO_IMPORT_SHOPEE,
} from "../actions";
import http from "@/Http"
import { ShopeeOrder } from "@/Interfaces/Shopee";
import {
  SET_IMPORTED_ORDERS_SHOPEE_LIST_STATE,
  SET_ORDERS_ON_PRODUCTION_SHOPEE_LIST_STATE,
} from "../mutations";

export interface ShopeeStateInterface {
  importados: ShopeeOrder[];
  producao: ShopeeOrder[];
}

export const ShopeeState: Module<ShopeeStateInterface, State> = {
  mutations: {
    [SET_IMPORTED_ORDERS_SHOPEE_LIST_STATE](state, ordersList: ShopeeOrder[]) {
      state.importados = ordersList;
    },
    [SET_ORDERS_ON_PRODUCTION_SHOPEE_LIST_STATE](
      state,
      ordersList: ShopeeOrder[]
    ) {
      state.producao = ordersList;
    },
  },
  actions: {
    [SEND_FILE_TO_IMPORT_SHOPEE](_, formData: FormData) {
      http.post("/pedidos/shopee/importar", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          console.log(res.data);
        })
        .catch((res) => {
          console.log(res);
        });
    },
    [GET_IMPORTED_ORDERS_SHOPEE]({ commit }) {
      http.get("/pedidos/shopee/importados")
        .then((res) => {
          commit(SET_IMPORTED_ORDERS_SHOPEE_LIST_STATE, res.data);
        })
        .catch((res) => {
          console.log(res);
        });
    },
    [GET_ORDERS_ON_PRODUCTION_SHOPEE]({ commit }) {
      http.get("/pedidos/shopee/producao")
        .then((res) => {
          commit(SET_ORDERS_ON_PRODUCTION_SHOPEE_LIST_STATE, res.data);
        })
        .catch((res) => {
          console.log(res);
        });
    },
  },
};
