
import { defineComponent, ref } from 'vue';
import { useStore } from '@/Store';
import { computed } from '@vue/reactivity';
import { PRODUCT_LIST_NEXT_PAGE, PRODUCT_LIST_PREVIOUS_PAGE, PRODUCT_LIST_SET_PAGE } from '@/Store/mutations';

export default defineComponent({
    name: "Paginator",
    emits: ["pageSwitch"],
    watch: {
        activePage() {
            this.$emit("pageSwitch", this.activePage)
            this.activePage
        }
    },
    setup() {
        const store = useStore()
        const activePage = computed(() => store.state.ProductsState.ProductListPageActive)
        const pagesRange = ref([1, 2, 3])

        const verifyPagesRange = (plus = true) => {
            if (!pagesRange.value.includes(activePage.value)) {
                pagesRange.value = pagesRange.value.map(item => {
                    if (plus === true) {
                        return item + 3
                    } else {
                        if (item > 3) {
                            return item - 3
                        } else {
                            return item
                        }
                    }
                })
            }
        }

        const nextPage = () => {
            store.commit(PRODUCT_LIST_NEXT_PAGE)
            verifyPagesRange(true)
        }

        const previousPage = () => {
            if (activePage.value > 1) {
                store.commit(PRODUCT_LIST_PREVIOUS_PAGE)
            }
            verifyPagesRange(false)
        }

        const selectPage = (page: number) => {
            store.commit(PRODUCT_LIST_SET_PAGE, page)
        }

        if (activePage.value === undefined) {
            selectPage(1)
        }

        return {
            activePage,
            pagesRange,
            nextPage,
            previousPage,
            selectPage
        }
    }
})
