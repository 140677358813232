
import { useStore } from '@/Store';
import { defineComponent, ref } from 'vue';
import http from "@/Http"
import { computed } from 'vue';
import { GET_IMPORTED_ORDERS_SHOPEE, GET_ORDERS_ON_PRODUCTION_SHOPEE } from '@/Store/actions';


export default defineComponent({
    name: "OrdersToPrint",
    setup() {
        const store = useStore()
        const loading = ref(false)
        const printing = ref([] as string[])

        const getAllOrdersToPrintOnState = () => {
            const shopeeState = store.state.ShopeeState
            const list = []
            if (shopeeState.importados && shopeeState.producao) {
                list.push(...shopeeState.importados.filter(order => order.printed == false))
                list.push(...shopeeState.producao.filter(order => order.printed == false))
            }
            return list
        }
        const toPrintList = computed(() => getAllOrdersToPrintOnState())

        const reload = () => {
            loading.value = true
            store.dispatch(GET_IMPORTED_ORDERS_SHOPEE)
            .then(() => {
                store.dispatch(GET_ORDERS_ON_PRODUCTION_SHOPEE)
                .then(() => {
                    loading.value = false
                })
            })
        }

        const print = (order: string) => {
            printing.value.push(order)
            http.get(`/pedidos/papeis/${order}`)
            .then(res => {
                const printWindow = window.open();
                if (printWindow) {
                    printWindow.document.open();
                    printWindow.document.write(res.data);
                    printWindow.document.body.setAttribute("style", "zoom: 66%;")
                    printWindow.document.close();
        
                    printWindow.onload = () => {
                        printWindow.print();
                            http.post(`/pedidos/shopee/${order}/impresso`)
                            .then(res => {
                                console.log(res)
                                console.log(`Pedido com id:${order} foi marcado como impresso`)
                                reload()
                            })
                    };
                    printWindow.onafterprint = () => {
                        printWindow.close()
                    }
                }
            })
        }
        

        return {
            toPrintList,
            print,
            reload,
            loading,
            printing
        }
    }

})
