import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "integrations" }
const _hoisted_2 = { class: "mt-3 d-flex justify-content-around flex-wrap text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Integration = _resolveComponent("Integration")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Integration, {
        imgsrc: "https://bossainvest.com/wp-content/uploads/2020/09/Melhor-Envio-1.jpg",
        imgalt: "Melhor Envio",
        to: "/melhorenvio"
      }),
      _createVNode(_component_Integration, { opacity: 0 }),
      _createVNode(_component_Integration, { opacity: 0 }),
      _createVNode(_component_Integration, { opacity: 0 }),
      _createVNode(_component_Integration, { opacity: 0 })
    ])
  ]))
}