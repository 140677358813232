
import Auth from '@/Auth';
import User from '@/Interfaces/User';
import { useStore } from '@/Store';
import { computed } from '@vue/reactivity';
import { defineComponent, watchEffect } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
    name: "login",
    setup() {
        const store = useStore()

        const router = useRouter()

        const userConnected = computed(() => Auth.user(store))

        watchEffect(() => {
            if (userConnected.value != null) {
                router?.push({ name: "Início" }).then(() => { router.go(0) })
            }
            userConnected.value
        })

        const user: User = {
            username: "",
            password: ""
        }

        const submit = () => {
            Auth.login(user, store)
        }

        return {
            user,
            submit,
            userConnected
        }
    }
})
