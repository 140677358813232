import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "offcanvas-body"
}
const _hoisted_2 = { class: "navbar-nav justify-content-end flex-grow-1 pe-3" }
const _hoisted_3 = { class: "nav-item" }
const _hoisted_4 = {
  key: 1,
  class: "offcanvas-body text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Navitem = _resolveComponent("Navitem")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.user !== null && _ctx.user !== undefined)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("ul", _hoisted_2, [
          _createVNode(_component_Navitem, { toName: "Início" }, {
            default: _withCtx(() => [
              _createTextVNode("Home")
            ]),
            _: 1
          }),
          _createVNode(_component_Navitem, { toName: "Produtos" }, {
            default: _withCtx(() => [
              _createTextVNode("Produtos")
            ]),
            _: 1
          }),
          _createVNode(_component_Navitem, { toName: "Pedidos" }, {
            default: _withCtx(() => [
              _createTextVNode("Pedidos")
            ]),
            _: 1
          }),
          _createElementVNode("li", _hoisted_3, [
            _createElementVNode("a", {
              class: "nav-link text-danger",
              href: "#",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.logout && _ctx.logout(...args)))
            }, " Desconectar ")
          ])
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_4, [
        _createVNode(_component_router_link, {
          to: {name: 'Login'},
          class: "btn w-75 bg-pinkfofuras"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Conectar ")
          ]),
          _: 1
        })
      ]))
}