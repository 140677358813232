import OrdersShipping from "@/Interfaces/OrdersShipping/OrdersShipping";
import { Module } from "vuex";
import { State } from "..";
import { ADD_IN_PRODUCTION_ORDERS_SHIPPING_LIST_STATE, ADD_TO_PRINT_ORDERS_SHIPPING_LIST_STATE, ADD_TO_PRODUCT_ORDERS_SHIPPING_LIST_STATE, DELETE_IN_PRODUCTION_ORDERS_SHIPPING_LIST_STATE, DELETE_TO_PRINT_ORDERS_SHIPPING_LIST_STATE, DELETE_TO_PRODUCT_ORDERS_SHIPPING_LIST_STATE, SET_IN_PRODUCTION_ORDERS_SHIPPING_LIST_STATE, SET_TO_PRINT_ORDERS_SHIPPING_LIST_STATE, SET_TO_PRODUCT_ORDERS_SHIPPING_LIST_STATE, UPDATE_AN_ORDERS_SHIPPING } from "../mutations";
import { FLUSH_ALL_ORDERS_SHIPPING, GET_ORDERS_FROM_ORDERS_SHIPPING, GET_ORDERS_SHIPPING_TO_PRINT, GET_ORDERS_SHIPPING_TO_PRODUCT, GET_ORDERS_SHIPPING_WAS_IN_PRODUCTION, SET_ORDERS_SHIPPING_AS_PACKED, SET_ORDERS_SHIPPING_AS_PRINTED } from "../actions";
import http from "@/Http";
import Order from "@/Interfaces/Order";

export interface OrdersShippingStateInterface {
    toProduct: OrdersShipping[]
    inProduction: OrdersShipping[]
    toPrint: OrdersShipping[]
}

export const OrdersShippingState: Module<OrdersShippingStateInterface, State> = {
    mutations: {
        [SET_TO_PRODUCT_ORDERS_SHIPPING_LIST_STATE](state, toProductOrdersShippingList: OrdersShipping[]) {
            state.toProduct = toProductOrdersShippingList
        },
        [SET_IN_PRODUCTION_ORDERS_SHIPPING_LIST_STATE](state, inProductionOrdersShippingList: OrdersShipping[]) {
            state.inProduction = inProductionOrdersShippingList
        },
        [SET_TO_PRINT_ORDERS_SHIPPING_LIST_STATE](state, toPrintOrdersShippingList: OrdersShipping[]) {
            state.toPrint = toPrintOrdersShippingList
        },
        [ADD_TO_PRODUCT_ORDERS_SHIPPING_LIST_STATE](state, OrdersShippingToAdd: OrdersShipping) {
            if (!state.toProduct.filter(ordersShipping => ordersShipping.id == OrdersShippingToAdd.id)) {
                state.toProduct.push(OrdersShippingToAdd)
            }
        },
        [ADD_IN_PRODUCTION_ORDERS_SHIPPING_LIST_STATE](state, OrdersShippingToAdd: OrdersShipping) {
            if (!state.inProduction.filter(ordersShipping => ordersShipping.id == OrdersShippingToAdd.id)) {
                state.inProduction.push(OrdersShippingToAdd)
            }
        },
        [ADD_TO_PRINT_ORDERS_SHIPPING_LIST_STATE](state, OrdersShippingToAdd: OrdersShipping) {
            if (!state.toPrint.filter(ordersShipping => ordersShipping.id == OrdersShippingToAdd.id)) {
                state.toPrint.push(OrdersShippingToAdd)
            }
        },
        [DELETE_TO_PRODUCT_ORDERS_SHIPPING_LIST_STATE](state, OrdersShippingToDelete: OrdersShipping) {
            state.toProduct = state.toProduct.filter(ordersShipping => ordersShipping.id != OrdersShippingToDelete.id)
        },
        [DELETE_IN_PRODUCTION_ORDERS_SHIPPING_LIST_STATE](state, OrdersShippingToDelete: OrdersShipping) {
            state.toProduct = state.toProduct.filter(ordersShipping => ordersShipping.id != OrdersShippingToDelete.id)
        },
        [DELETE_TO_PRINT_ORDERS_SHIPPING_LIST_STATE](state, OrdersShippingToDelete: OrdersShipping) {
            state.toProduct = state.toProduct.filter(ordersShipping => ordersShipping.id != OrdersShippingToDelete.id)
        },
        [UPDATE_AN_ORDERS_SHIPPING](state, OrdersShippingToUpdate: OrdersShipping) {
            state.toPrint = (state.toPrint as OrdersShipping[]).map(item => {
                if (item.id == OrdersShippingToUpdate.id) {
                    return OrdersShippingToUpdate
                } else {
                    return item
                }
            })
        }
    },
    actions: {
        [FLUSH_ALL_ORDERS_SHIPPING]({ dispatch }) {
            dispatch(GET_ORDERS_SHIPPING_TO_PRODUCT)
            dispatch(GET_ORDERS_SHIPPING_TO_PRINT)
            dispatch(GET_ORDERS_SHIPPING_WAS_IN_PRODUCTION)
        },
        [GET_ORDERS_SHIPPING_TO_PRODUCT]({ commit }) {
            http.get("/producao/remessa")
                .then(
                    res => {
                        commit(SET_TO_PRODUCT_ORDERS_SHIPPING_LIST_STATE, res.data)
                    }
                )
        },
        [GET_ORDERS_SHIPPING_TO_PRINT]({ commit }) {
            http.get("/producao/remessa?printed=false")
                .then(
                    res => {
                        commit(SET_TO_PRINT_ORDERS_SHIPPING_LIST_STATE, res.data)
                    }
                )
        },
        [GET_ORDERS_SHIPPING_WAS_IN_PRODUCTION]({ commit }) {
            http.get("/producao/remessa?inProduction=true")
                .then(
                    res => {
                        commit(SET_IN_PRODUCTION_ORDERS_SHIPPING_LIST_STATE, res.data)
                    }
                )
        },
        [GET_ORDERS_FROM_ORDERS_SHIPPING]({ commit }, OrdersShipping: OrdersShipping) {
            http.get(`/producao/remessa/${OrdersShipping.id}`)
                .then(
                    res => {
                        const newOrdersShipping = res.data as OrdersShipping
                        commit(UPDATE_AN_ORDERS_SHIPPING, newOrdersShipping)
                    }
                )
        },
        [SET_ORDERS_SHIPPING_AS_PRINTED]({ dispatch }, OrdersShippingPrinted: OrdersShipping) {
            http.post(`/producao/remessa/${OrdersShippingPrinted.id}/impresso`)
                .then(
                    () => {
                        dispatch(FLUSH_ALL_ORDERS_SHIPPING)
                    }
                )
        },
        [SET_ORDERS_SHIPPING_AS_PACKED]({ dispatch }, OrdersShippingToPack: OrdersShipping) {
            http.post(`/producao/remessa/${OrdersShippingToPack.id}/embalado`)
            .then(
                () => {
                    dispatch(FLUSH_ALL_ORDERS_SHIPPING)
                }
            )
        }
    }
}