
import { computed, defineComponent, ref, watchEffect } from 'vue'
import { useStore } from '@/Store'
import { GET_ORDERS_WAS_IN_PRODUCTION, PACK_AN_ORDER } from '@/Store/actions'
import { REMOVE_ORDER_WAS_IN_PRODUCTION_LIST_STATE } from "@/Store/mutations"
import Order from "@/Interfaces/Order"

export default defineComponent({
    name: "OrderWasInProduction",
    setup() {
        const store = useStore()
        const ordersWasInProductionList = computed(() => store.state.OrderState.OrdersWasInProduction)
        const ordersData = ref([] as unknown as { id: number, min_days: number, paid_at: number }[])
        const baseUrl = "https://ateliefofurasdarose.lojavirtualnuvem.com.br/admin/orders/"

        const updateListOfOrders = () => {
            store.dispatch(GET_ORDERS_WAS_IN_PRODUCTION)
        }

        const redirectTo = (orderId: number) => {
            window.open(`${baseUrl}${orderId}`, "_blank")
        }

        const dateFormat = (paid_at: string, min_days: number) => {
            const date = new Date(paid_at)
            date.setTime(date.getTime() + ((60 * 60 * 24) * min_days) * 1000)
            return date.toISOString().slice(0, 10).split("-").reverse().join("/")
        }

        const packAnOrder = (order: Order) => {
            document.querySelector(`#btn${order.id}`)?.classList.add("disabled")
            store.dispatch(PACK_AN_ORDER, order).then(() => {
                store.commit(REMOVE_ORDER_WAS_IN_PRODUCTION_LIST_STATE, order)
            })
        }

        updateListOfOrders()

        return {
            ordersWasInProductionList,
            redirectTo,
            ordersData,
            dateFormat,
            packAnOrder
        }
    }
})
