
import { useStore } from '@/Store';
import { ADD_LABEL_FROM_ADD_TO_CART_ON_CART, GET_LABELS_ON_CART } from '@/Store/actions';
import { computed, ref } from '@vue/reactivity';
import { defineComponent } from 'vue';
import ButtonReload from '../ButtonReload.vue';
import Label from './Label.vue';
import { ADD_LABEL_ON_ADD_TO_CART_STATE } from '@/Store/mutations';
// import KeyboardNumber from './KeyboardNumber.vue';

export default defineComponent({
    name: "CartLabels",
    setup() {
        const store = useStore();

        const labelsOnCart = computed(() => store.state.LabelState.labelsOnCart)
        const labelsToAddOnCart = computed(() => store.state.LabelState.labelsToAddOnCart)

        const reloading = () => {
            store.dispatch(GET_LABELS_ON_CART);
        }

        reloading()

        // const keyboardActive = ref(false)

        const orderNumberLabel = ref("");

        // const changeOrderNumber = (value: string) => {
        //     orderNumberLabel.value = value
        // }

        // const activeKeyboard = (event: Event) => {
        //     if (event.isTrusted) {
        //         setTimeout(() => {
        //             keyboardActive.value = true
        //         }, 500)
        //     }
        // }

        const addLabel = () => {
            const label = {
                id: "",
                order: Number(orderNumberLabel.value),
                client: ""
            }
            store.commit(ADD_LABEL_ON_ADD_TO_CART_STATE, label)
            orderNumberLabel.value = ""
            store.dispatch(ADD_LABEL_FROM_ADD_TO_CART_ON_CART, label)
        }

        const focusInput = (event: Event) => {
            const formLabel = document.querySelector("#inputLabel > form > input") as HTMLElement
            if (event.isTrusted) {
                formLabel.focus()
            }
        }

        const focusOutInput = () => {
            const button = document.querySelector("#btnAdicionarLabel") as HTMLElement
            button.click()
            orderNumberLabel.value = ""
        }

        // const clickOut = (event: Event) => {
        //     const btnAdicionarLabel = document.querySelector("#btnAdicionarLabel") as HTMLElement;
        //     btnAdicionarLabel.click();
        //     orderNumberLabel.value = "";
        //     keyboardActive.value = false
        // }

        return {
            labelsOnCart,
            labelsToAddOnCart,
            orderNumberLabel,
            // changeOrderNumber,
            reloading,
            addLabel,
            focusInput,
            focusOutInput,
            // activeKeyboard,
            // keyboardActive,
            // clickOut
        };
    },
    components: {
        ButtonReload,
        Label,
        // KeyboardNumber
    }
})
