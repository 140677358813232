
import { useStore } from '@/Store';
import { SELECT_PRODUCT_REFERENCE } from '@/Store/actions';
import { computed } from '@vue/reactivity';
import { defineComponent, watchEffect } from 'vue';
import { useRoute } from 'vue-router';
import TablePositions from '@/components/Products/TablePositions.vue';

export default defineComponent({
    name: "Product",
    components: {
        TablePositions
    },
    setup() {
        const store = useStore()
        let product = computed(() => store.state.ProductsState.ProductSelected)
        
        const urlID = useRoute().params.id
        
        if (!product.value) {
            store.dispatch(SELECT_PRODUCT_REFERENCE, urlID)
        }
        
        
        return {
            product
        }
    }
})
