import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "@/Views/Home.vue";
import Products from "@/Views/Products/Products.vue";
import Product from "@/Views/Products/Product.vue";
import Integrations from "@/Views/Integrations/Integrations.vue";
import Login from "@/Views/Login.vue";
import Melhorenvio from "@/Views/Melhorenvio/Melhorenvio.vue";
import OrdersHome from "@/Views/Orders/OrdersHome.vue";
import OrdersToPrint from "@/Views/Orders/OrdersToPrint.vue";
import OrdersWasInProduction from "@/Views/Orders/OrdersWasInProduction.vue";
import ShopeePage from "@/Views/Shopee/ShopeePage.vue";
import ProductsHome from "@/Views/Products/ProductsHome.vue";
import OrdersShippingPage from "@/Views/OrdersShipping/OrdersShippingPage.vue"
import CutterProductionPage from "@/Views/CutterProduction/CutterProductionPage.vue"

const Routes: Array<RouteRecordRaw> = [
  {
    path: "/producao/",
    name: "App",
    children: [
      {
        path: "",
        name: "Início",
        component: Home,
      },
      {
        path: "produtos",
        children: [
          {
            path: "lista",
            name: "Lista de Produtos",
            component: Products,
          },
          {
            path: ":id",
            name: "Produto",
            component: Product,
          },
          {
            path: "",
            name: "Produtos",
            component: ProductsHome,
          },
          {
            path: "cortadores/producao",
            name: "Producão dos Cortadores",
            component: CutterProductionPage
          }
        ],
      },
      {
        path: "shopee",
        name: "Shopee",
        component: ShopeePage,
      },
      {
        path: "pedidos",
        children: [
          {
            path: "",
            name: "Pedidos",
            component: OrdersHome,
          },
          {
            path: "imprimir",
            name: "Pedidos Para Imprimir",
            component: OrdersToPrint,
          },
          {
            path: "produzindo",
            name: "Pedidos Sendo Produzidos",
            component: OrdersWasInProduction,
          },
        ],
      },
      {
        path: "remessas",
        name: "Remessas",
        component: OrdersShippingPage
      },
      {
        path: "integracoes",
        name: "Integrações",
        component: Integrations,
      },
      {
        path: "melhorenvio",
        children: [
          {
            path: "",
            name: "Melhor Envio",
            component: Melhorenvio,
          },
          {
            path: "connection",
            name: "Melhor Envio Opções",
            component: Melhorenvio,
          },
        ],
      },
      {
        path: "/login",
        name: "Login",
        component: Login,
      },
    ],
  },
  {
    path: "",
    name: "Fofuras Da Rose",
    component: {},
    beforeEnter() {
      window.location.href = "https://linktr.ee/fofurasdarose";
    },
  },
];

const Router = createRouter({
  history: createWebHistory(),
  routes: Routes,
});

export default Router;
