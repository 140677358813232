
import { defineComponent } from 'vue';
import ImportOrdersArea from "@/components/Shopee/ImportOrdersArea.vue";
import OrdersVizualization from "@/components/Shopee/OrdersVizualization.vue";
import OrdersToPrint from "@/components/Shopee/OrdersToPrint.vue";

    export default defineComponent({
        name: "ShopeePage",
        components: {
            ImportOrdersArea,
            OrdersVizualization,
            OrdersToPrint
        }
        
    })
