
import Order from '@/Interfaces/Order';
import OrdersShipping from '@/Interfaces/OrdersShipping/OrdersShipping';
import { useStore } from '@/Store';
import { GET_ORDERS_FROM_ORDERS_SHIPPING, SET_ORDERS_SHIPPING_AS_PACKED, SET_ORDERS_SHIPPING_AS_PRINTED } from '@/Store/actions';
import { defineComponent, computed, ref } from 'vue';

export default defineComponent({
    name: "OrdersShippingList",
    setup() {
        const store = useStore()
        const toProduct = computed(() => store.state.OrdersShippingState.toProduct)
        const toPrint = computed(() => store.state.OrdersShippingState.toPrint)
        const inProduction = computed(() => store.state.OrdersShippingState.inProduction)
        const activePage = ref(1)

        const printOrdersShipping = (OrdersShippingToPrint: OrdersShipping) => {
            store.dispatch(GET_ORDERS_FROM_ORDERS_SHIPPING, OrdersShippingToPrint)
            const interval = setInterval(() => {
                const OrdersShippingSelected = toPrint.value.filter(item => item.id == OrdersShippingToPrint.id)[0]
                if (OrdersShippingSelected.orders != undefined) {
                    if (OrdersShippingSelected.orders.length > 0) {
                        const orders = OrdersShippingSelected.orders
                        orders.forEach(order => window.open("https://ateliefofurasdarose.lojavirtualnuvem.com.br/admin/orders/" + order.id, "_blank"))
                        clearInterval(interval)
                    } else {
                        console.log("order not printing")
                        clearInterval(interval)
                    }
                    store.dispatch(SET_ORDERS_SHIPPING_AS_PRINTED, OrdersShippingSelected)
                }
            }, 1000)
        }

        const packingOrdersShipping = (OrdersShippingToPacking: OrdersShipping) => {
            store.dispatch(SET_ORDERS_SHIPPING_AS_PACKED, OrdersShippingToPacking)
        }

        const pageOptions: any = {
            0: {
                name: "Para Produzir",
                value: toProduct
            },
            1: {
                name: "Para Imprimir",
                value: toPrint,
                button: "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"20\" height=\"20\" fill=\"currentColor\" class=\"bi bi-printer\" viewBox=\"0 0 16 16\"><path d=\"M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1\" /><path d=\"M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1\" /></svg>",
                action: printOrdersShipping
            },
            2: {
                name: "Para Embalar",
                value: inProduction,
                button: "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"16\" height=\"16\" fill=\"currentColor\" class=\"bi bi-box-seam\" viewBox=\"0 0 16 16\"><path d=\"M8.186 1.113a.5.5 0 0 0-.372 0L1.846 3.5l2.404.961L10.404 2zm3.564 1.426L5.596 5 8 5.961 14.154 3.5zm3.25 1.7-6.5 2.6v7.922l6.5-2.6V4.24zM7.5 14.762V6.838L1 4.239v7.923zM7.443.184a1.5 1.5 0 0 1 1.114 0l7.129 2.852A.5.5 0 0 1 16 3.5v8.662a1 1 0 0 1-.629.928l-7.185 2.874a.5.5 0 0 1-.372 0L.63 13.09a1 1 0 0 1-.63-.928V3.5a.5.5 0 0 1 .314-.464z\"/></svg>",
                action: packingOrdersShipping
            }
        }
        const getActiveList = (): any => {
            return pageOptions[activePage.value].value.value
        }

        const getActivePage = (): any => {
            return pageOptions[activePage.value]
        }

        const changePage = (e: any) => {
            const id = e.target.id ? e.target.id : null
            if (activePage.value != id) {
                activePage.value = id
            }

        }

        const buttonClicked = (e: PointerEvent, id: number) => {
            document.querySelector(`#buttonId${id}`)?.setAttribute("disabled", "")
        }

        return {
            pageOptions,
            activePage,
            changePage,
            getActiveList,
            getActivePage,
            buttonClicked
        }
    }
})
