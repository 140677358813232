import { Module } from "vuex";
import { State } from "..";
import { REMOVE_ORDER_TO_PRINT_LIST_STATE, REMOVE_ORDER_WAS_IN_PRODUCTION_LIST_STATE, SET_ORDERS_TO_PRINT_LIST_STATE, SET_ORDERS_TO_PRODUCT_LIST_STATE, SET_ORDERS_WAS_IN_PRODUCTION_LIST_STATE, SET_ORDERS_WAS_PRINTED_LIST_STATE } from "../mutations";
import Order from "@/Interfaces/Order";
import { GET_ORDERS_TO_PRINT, GET_ORDERS_TO_PRODUCT, GET_ORDERS_WAS_IN_PRODUCTION, GET_ORDERS_WAS_PRINTED, PACK_AN_ORDER, SET_ORDER_PRINTED } from "../actions";
import http from "@/Http"

export interface OrderStateInterface {
    OrdersToPrint: Order[]
    OrdersWasPrinted: Order[]
    OrdersWasInProduction: Order[]
    OrdersToProduct: Order[]
}

export const OrderState: Module<OrderStateInterface, State> = {
    mutations: {
        [SET_ORDERS_TO_PRINT_LIST_STATE](state, ordersList: Order[]) {
            state.OrdersToPrint = ordersList?.filter(item => item.printed == "0")
        },
        [REMOVE_ORDER_TO_PRINT_LIST_STATE](state, orderToRemove: Order) {
            state.OrdersToPrint = state.OrdersToPrint?.filter(item => item != orderToRemove)
        },
        [SET_ORDERS_WAS_PRINTED_LIST_STATE](state, ordersList: Order[]) {
            state.OrdersWasPrinted = ordersList?.filter(item => item.printed == "1").reverse()
        },
        [SET_ORDERS_TO_PRODUCT_LIST_STATE](state, ordersList: Order[]) {
            state.OrdersToProduct = ordersList
        },
        [SET_ORDERS_WAS_IN_PRODUCTION_LIST_STATE](state, ordersList: Order[]) {
            state.OrdersWasInProduction = ordersList
        },
        [REMOVE_ORDER_WAS_IN_PRODUCTION_LIST_STATE](state, orderToRemove: Order) {
            state.OrdersWasInProduction = state.OrdersWasInProduction.filter(item => item != orderToRemove)
        }
    },
    actions: {
        [GET_ORDERS_TO_PRINT]({ commit }) {
            http.get("/pedidos?printed=0&status=2").then(response => {
                commit(SET_ORDERS_TO_PRINT_LIST_STATE, response.data)
            })
        },
        [SET_ORDER_PRINTED]({ commit }, order: Order) {
            http.post(`/pedidos/${order.id}/impresso`).then(response => {
                if (response.data) {
                    commit(REMOVE_ORDER_TO_PRINT_LIST_STATE, order)
                }
            })
        },
        [GET_ORDERS_WAS_PRINTED]({ commit }) {
            http.get("/pedidos?printed=1").then(response => {
                commit(SET_ORDERS_WAS_PRINTED_LIST_STATE, response.data)
            })
        },
        [GET_ORDERS_TO_PRODUCT]({commit}) {
            http.get(`/pedidos/para-imprimir`).then(response => {
                if (response.data) {
                    commit(SET_ORDERS_TO_PRODUCT_LIST_STATE, response.data)
                }
            })
        },
        [GET_ORDERS_WAS_IN_PRODUCTION]({commit}) {
            http.get(`/pedidos/produzindo`).then(response => {
                if (response.data) {
                    commit(SET_ORDERS_WAS_IN_PRODUCTION_LIST_STATE, response.data)
                }
            })
        },
        [PACK_AN_ORDER]({commit}, order: Order) {
            http.post(`/pedidos/${order.id}/enviar`).then(response => {
                if (response.data) {
                    commit(REMOVE_ORDER_WAS_IN_PRODUCTION_LIST_STATE, order)
                }
            })
        }
    }
}