import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "product",
  class: "text-center pb-3"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "mt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TablePositions = _resolveComponent("TablePositions")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        class: "rounded img",
        src: _ctx.product?.img
      }, null, 8, _hoisted_2),
      _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.product?.name || "Carregando ..."), 1)
    ]),
    (_ctx.product)
      ? (_openBlock(), _createBlock(_component_TablePositions, { key: 0 }))
      : _createCommentVNode("", true)
  ], 64))
}