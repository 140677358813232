
import { defineComponent } from 'vue';
import OffcanvasBody from './OffcanvasBody.vue';
import OffcanvasHeader from './OffcanvasHeader.vue';

export default defineComponent({
    name: "Offcanvas",
    components: {
        OffcanvasHeader,
        OffcanvasBody
    }
})
