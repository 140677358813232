import { InjectionKey } from "vue"
import { createStore, Store, useStore as vuexUseStore } from "vuex"
import { AlertState, AlertStateInterface } from "./alert"
import { LabelState , LabelStateInterface } from "./labelMelhoEnvio"
import { PanelPositionInterface, PanelPositionsState } from "./panelPositions"
import { ProductsState , ProductsStateInterface } from "./products"
import { UserState , UserStateInterface } from "./user"
import { OrderState, OrderStateInterface } from "./orders"
import { ShopeeState, ShopeeStateInterface } from "./shopee"
import { OrdersShippingState, OrdersShippingStateInterface } from "./ordersShipping"
import { CutterProductionState, CutterProductionStateInterface } from "./cutterProduction"

export interface State {
    UserState: UserStateInterface
    AlertState: AlertStateInterface
    LabelState: LabelStateInterface
    ProductsState: ProductsStateInterface
    PanelPositionsState: PanelPositionInterface
    OrderState: OrderStateInterface
    ShopeeState: ShopeeStateInterface
    OrdersShippingState: OrdersShippingStateInterface
    CutterProductionState: CutterProductionStateInterface
    AppIsLoading: boolean
}

export const key: InjectionKey<Store<State>> = Symbol()


export const store = createStore<State>({
    state: {
        UserState: {
            user: null
        },
        AlertState: {
            alert: null
        },
        LabelState: {
            labelsCanFullFillList: [],
            labelsOnCart: [],
            labelsToAddOnCart: []
        },
        ProductsState: {
            ProductsList: [],
            ProductListPageActive: 1
        },
        PanelPositionsState: {
            PanelsList: [],
            PositionsList: []
        },
        OrderState: {
            OrdersToPrint: [],
            OrdersWasPrinted: [],
            OrdersWasInProduction: [],
            OrdersToProduct: []
        },
        ShopeeState: {
            importados: [],
            producao: []
        },
        OrdersShippingState: {
            toProduct: [],
            inProduction: [],
            toPrint: []
        },
        CutterProductionState: {
            shippingsToProduct: []
        },
        AppIsLoading: true
    },
    modules: {
        UserState,
        AlertState,
        LabelState,
        ProductsState,
        PanelPositionsState,
        OrderState,
        ShopeeState,
        OrdersShippingState,
        CutterProductionState
    }
    
    
})

export function useStore(): Store<State> {
    return vuexUseStore(key)
}