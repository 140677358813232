
import { AlertType } from '@/Interfaces/Alert';
import Panel from '@/Interfaces/Panels/Panel';
import PanelPosition from '@/Interfaces/Panels/PanelPosition';
import { useStore } from '@/Store';
import { GET_PANELS_LIST, GET_POSITIONS_LIST, POST_PRODUCT_POSITION } from '@/Store/actions';
import { ALERT } from '@/Store/mutations';
import { computed, ref } from '@vue/reactivity';
import { defineComponent, watchEffect } from 'vue';
import { useRoute } from 'vue-router';

export default defineComponent({
    name: "PositionRegister",
    setup() {
        const store = useStore()

        store.dispatch(GET_PANELS_LIST)

        const panelsList = computed(() => store.state.PanelPositionsState.PanelsList)
        const positionsList = computed(() => store.state.PanelPositionsState.PositionsList)

        const columns = computed(() => (new Set(positionsList.value?.map(position => position.column))).values())
        const rows = computed(() => (new Set(positionsList.value?.map(postion => postion.row))).values())

        const panelSelected = ref({} as Panel)
        const columnSelected = ref("A")
        const rowSelected = ref(1)
        const productID = useRoute().params.id

        watchEffect(() => {
            if (panelsList.value !== undefined) {
                panelSelected.value = panelsList.value[0]
            }
            panelsList.value
        })

        watchEffect(() => {
            if (panelSelected.value.id) {
                store.dispatch(GET_POSITIONS_LIST, panelSelected.value)
            }
            panelSelected.value
        })

        const selectPanel = ({ target }: any) => {
            panelSelected.value = panelsList.value.find(item => item.id == target.value) as Panel
        }

        const selectColumn = ({ target }: any) => {
            columnSelected.value = target.value
        }

        const selectRow = ({ target }: any) => {
            rowSelected.value = target.value
        }

        const savePosition = () => {
            const position = positionsList.value.find(item => { 
                return item.column == columnSelected.value && item.row == rowSelected.value && item.panel_id == panelSelected.value.id
            })
            if (position) {
                position.product_id = productID as unknown as number
                store.dispatch(POST_PRODUCT_POSITION, position)
            } else {
                store.commit(ALERT, {
                    text: "Posição indisponível, tente outra!",
                    type: AlertType.WARNING
                })
            }
        }

        return {
            selectPanel,
            selectColumn,
            selectRow,
            savePosition,
            panelsList,
            columns,
            rows,
            columnSelected,
            rowSelected
        }
    }
})
