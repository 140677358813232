
import { defineComponent } from "vue";
import Cutter from "@/components/CutterProductionPage/Cutter.vue";
import { key, useStore } from "@/Store";
import { GET_ORDERS_SHIPPING_WAS_IN_PRODUCTION_TO_CUTTER, GET_PRODUCTS_TO_PRODUCT_FROM_SHOPEE } from "@/Store/actions";

export default defineComponent({
    name: "CutterProductionPage",
    components: {
        Cutter
    },
    setup () {
        const store = useStore()
        store.dispatch(GET_ORDERS_SHIPPING_WAS_IN_PRODUCTION_TO_CUTTER)
        store.dispatch(GET_PRODUCTS_TO_PRODUCT_FROM_SHOPEE)
        const log = () => {
            console.log("checked")
        }
        return {
            log
        }
    }
})
