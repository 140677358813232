
import { defineComponent, watchEffect } from 'vue';
import Menu from "@/components/Menu/Menu.vue";
import Alert from './components/Alert.vue';
import Footer from './components/Footer/Footer.vue';
import Auth from './Auth';
import BackRouteButton from './components/Navigation/BackRouteButton.vue';
import { useStore } from './Store';
import { ALERT } from './Store/mutations';
import { Alert as AlertInterface, AlertType } from './Interfaces/Alert';
import { computed } from '@vue/reactivity';
import { useRoute, useRouter } from 'vue-router';

export default defineComponent({
  name: 'App',
  components: {
    Menu,
    Alert,
    Footer,
    BackRouteButton
  },
  watch: {
    $route(to) {
      document.title = to.name + " - Fofuras da rose"
      this.checkingPerms(to)
    }
  },
  setup() {
    const store = useStore()

    const router = useRouter()

    const AppIsLoading = computed(() => store.state.AppIsLoading)

    const checkingPerms = (to: any) => {
      if (!AppIsLoading.value && !Auth.user(store) && !["Login", "Fofuras Da Rose"].includes(to.name)) {
        router.push({name: "Login"})
        store.commit(ALERT, {
          text: "Necessário login para acessar página!",
          type: AlertType.DANGER
        } as AlertInterface)
      }
    }

    
    
    checkingPerms(router.currentRoute)

    Auth.userIsValid(store)

    return {
      store,
      AppIsLoading,
      checkingPerms
    }
  }
});
