
import Router from '@/router';
import { defineComponent } from 'vue';

export default defineComponent({
    name: "Integration",
    props: {
        imgsrc: {
            type: String,
            default: ""
        },
        imgalt: {
            type: String,
            default: "Erro"
        },
        to: {
            type: String,
            default: "/"
        },
        opacity: {
            type: Number,
            default: 1
        }
    },
    setup(props) {

        const routerPush = () => {
            if (props.opacity > 0) {
            Router.push(props.to)
            }
        }

        return {
            routerPush
        }
    }
})
