
import { defineComponent } from 'vue';
import Router from '@/router';


export default defineComponent({
    name: "Shortcut",
    props: ["to"],
    setup(props) {
        const directTo = () => {
            if (props.to) {
                Router.push(props.to)
            }
        }

        return {
            directTo
        }
    }
})
