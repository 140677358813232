import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9ab80ce2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card-body d-flex flex-column align-items-center justify-content-center" }
const _hoisted_2 = { class: "card-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "card my-2 px-4 py-4 bg-pinkfofuras text-center",
    id: "shortcut",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.directTo && _ctx.directTo(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "icon", {}, undefined, true),
      _createElementVNode("h6", _hoisted_2, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ])
    ])
  ]))
}