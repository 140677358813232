
import { defineComponent } from 'vue';
import Shortcut from '@/components/Home/Shortcut.vue';

export default defineComponent({
    name: "OrdersHome",
    components: {
        Shortcut
    }
})
