
import { defineComponent } from 'vue';
import Integration from '@/components/Integrations/Integration.vue';

export default defineComponent({
    name: "Integrations",
    components: {
        Integration
    }
})
